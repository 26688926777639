import React from 'react';
import styled from 'styled-components';
const appConfig = require('../../../src/data.json');

const MAX_WIDTH = appConfig.maxContentWidth;

export default function PrivacyPage() {
  return (
    <Wrapper>
      <div className="constraint">
        <h1>隱私政策</h1>
        <div>
          <p>
            非常歡迎您光臨「建鏵數位印刷」（以下簡稱本網站），
            <br />
            為了讓您能夠安心的使用本網站的各項服務與資訊，特此向您說明本網站的隱私權保護政策，
            <br />
            以保障您的權益，請您詳閱下列內容：
            <br />
          </p>
          <h4>個人資料之安全</h4>
          <p>
            保護會員的個人隱私是建鏵數位印刷重要的經營理念，在未經會員同意之下，我們絕不會將會員的個人資料提供予任何與本購物網站服務無關之第三人。會員應妥善保密自己的網路密碼及個人資料，不要將任何個人資料，尤其是網路密碼提供給任何人。在使用完建鏵數位印刷網站所提供的各項服務功能後，務必記得登出帳戶，若是與他人共享電腦或使用公共電腦，切記要關閉瀏覽器視窗。
          </p>
          <h4>個人資料的蒐集、處理及利用方式</h4>
          <p>
            當您造訪建鏵數位印刷或使用本網站所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，建鏵數位印刷不會將個人資料用於其他用途。
          </p>
          <h4>◆ 蒐集之目的：</h4>
          <p>
            蒐集之目的在於進行行銷業務、消費者、客戶管理與服務、網路購物及其他電子商務服務及與調查、統計與研究分析(法定特定目的項目編號為Ｏ四Ｏ、Ｏ九Ｏ、一四八、一五七)。建鏵數位印刷將藉由加入會員之過程或進行交易之過程來蒐集個人資料。
          </p>
          <h4>◆ 蒐集之個人資料類別：</h4>
          <p>
            建鏵數位印刷內蒐集的個人資料包括，
            <br />
            (1) C001辨識個人者： 如會員之姓名、地址、電話、電子郵件等資訊。
            <br />
            (2) C002辨識財務者： 如信用卡或金融機構帳戶資訊。
            <br />
            (3) C011個人描述：例如：性別、出生年月日等。
            <br />
          </p>
          <h4>◆ 利用期間、地區、對象及方式：</h4>
          <p>
            (1) 期間：會員當事人要求停止使用或建鏵數位印刷停止提供服務之日為止。
            <br />
            (2) 地區：會員之個人資料將用於台灣地區。
            <br />
            (3)
            利用對象及方式：會員之個人資料蒐集除用於建鏵數位印刷之會員管理、客戶管理之檢索查詢等功能外，亦將利用於辨識身份、金流服務、物流服務、行銷廣宣等。例示如下：
            <br />
          </p>
          <p>
            a.
            以會員身份使用建鏵數位印刷提供之各項服務時，於頁面中自動顯示會員資訊。
            <br />
            b.
            為遂行交易行為：會員對商品或勞務為預約、下標、購買、參與贈獎等之活動或從事其他交易時，關於商品配送、勞務提供、價金給付、回覆客戶之詢問、建鏵數位印刷對會員之詢問、相關售後服務及其他遂行交易所必要之業務。
            <br />
            c.
            宣傳廣告或行銷等：提供會員各種電子雜誌等資訊、透過電子郵件、郵件、電話等提供與服務有關之資訊。將會員所瀏覽之內容或廣告，依客戶之個人屬性或購買紀錄、建鏵數位印刷網站之瀏覽紀錄等項目，進行個人化作業、會員使用服務之分析、新服務之開發或既有服務之改善等。針對民調、活動、留言版等之意見，或其他服務關連事項，與會員進行聯繫。
            <br />
            d.
            回覆客戶之詢問：針對會員透過電子郵件、郵件、、傳真、電話或其他任何直接間接連絡方式向建鏵數位印刷所提出之詢問進行回覆。
            <br />
            e.
            其他業務附隨之事項：附隨於上述a至d.之利用目的而為建鏵數位印刷提供服務所必要之使用。
            <br />
            f.
            對於各別服務提供者之資訊提供：會員對服務提供者之商品或勞務為預約、下標、購買、參加贈獎活動或申請其他交易時，建鏵數位印刷於該交易所必要之範圍內，得將會員之個人資料檔案提供予服務提供者，並由服務提供者負責管理該個人資料檔案。建鏵數位印刷將以規約課予服務提供者依保障會員隱私權之原則處理個人資料之義務，但無法保證服務提供者會必然遵守。詳細內容，請向各別服務提供者洽詢。
            <br />
            g.
            其他：提供個別服務時，亦可能於上述規定之目的以外，利用個人資料。此時將在該個別服務之網頁載明其要旨。
            <br />
          </p>
          <h4>◆ 會員就個人資料之權利：</h4>
          <p>
            建鏵數位印刷所蒐集個人資料之當事人，依個人資料保護法得對建鏵數位印刷行使以下權利：
            <br />
            (1) 查詢或請求閱覽。
            <br />
            (2) 請求製給複製本。
            <br />
            (3) 請求補充或更正。
            <br />
            (4) 請求停止蒐集、處理或利用。
            <br />
            (5) 請求刪除。
            <br />
            會員如欲行使上述權利，可與建鏵數位印刷客服連絡進行申請。
            <br />
            <br />
            請注意！如拒絕提供加入會員所需必要之資料，
            <br />
            將可能導致無法享受完整服務或完全無法使用該項服務。
            <br />
          </p>
          <h4>資料安全保護</h4>
          <p>
            為保障會員的隱私及安全，建鏵數位印刷會員帳號資料會用密碼保護。建鏵數位印刷並盡力以合理之技術及程序，保障所有個人資料之安全。
          </p>
          <h4>個人資料查詢或更正的方式</h4>
          <p>
            會員對於其個人資料，有查詢及閱覽、製給複製本、補充或更正、停止電腦處理及利用、刪除等需求時，可以與客服中心聯絡，建鏵數位印刷將迅速進行處理。
          </p>
          <h4>與第三人共用個人資料之政策</h4>
          <p>
            建鏵數位印刷絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。前項但書之情形包括不限於：
            <br />
            ◆經由您書面同意。
            <br />
            ◆法律明文規定。
            <br />
            ◆為免除您生命、身體、自由或財產上之危險。
            <br />
            ◆與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，
            <br />
            &nbsp且資料經過提供者處理或蒐集著依其揭露方式無從識別特定之當事人。
            <br />
            ◆當您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受
            <br />
            &nbsp損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。
            <br />
            ◆有利於您的權益。
            <br />
            ◆本網站委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。
            <br />
          </p>
          <h4>Cookie之使用</h4>
          <p>
            為了提供您最佳的服務，建鏵數位印刷會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導至網站某些功能無法正常執行。
          </p>
          <h4>隱私權保護政策之修正</h4>
          <p>
            隨著市場環境的改變本公司將會不時修訂網站政策，建鏵數位印刷隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。會員如果對於建鏵數位印刷網站隱私權聲明、或與個人資料有關之相關事項有任何疑問，請與建鏵數位印刷客服聯絡。
          </p>
          <h4>服務聲明</h4>
          <p>
            建鏵數位印刷 <br />
            電子商務網站，隨著市場環境的改變本公司將會不時修訂網站政策，建鏵數位印刷保留隨時修改會員服務使用條款及各該使用規範或約定之權利，修正後的條款將刊登於網站上。
            <br />
            請會員自行詳加閱讀，不另外個別通知使用者，如果對於建鏵數位印刷網站隱私權聲明、或與個人資料有關之相關事項有任何疑問，請與建鏵數位印刷客服聯絡。
            <br />
          </p>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  flex: 1;
  padding: 40px 0;
  & .constraint {
    max-width: ${MAX_WIDTH - 200}px;
    margin: 0 auto;
    padding: 0px 20px;
    h1 {
      text-align: center;
      margin-bottom: 40px;
      @media (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 0px;
      }
    }
  }
`;
